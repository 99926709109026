import React, { useEffect } from 'react';
import { store } from '@/store'
import { IOTD } from './iotD';
import { IOTY } from './iotY';
import { IOTJDD } from './iotJDD';

function IOTUpgradeSecond() {

    useEffect(() => {
        // console.log(store.getState().projectInfo.shortCode);
    }, [])

    const ComponentJudgment = (projectName: string) => {
        console.log(projectName);
        let component = <></>;
        switch (projectName) {
            case 'Y1':
                component = <IOTY />;
                break;
            case 'JDD3':
                component = <IOTJDD />;
                break;
            default:
                component = <IOTD />;
        }
        return component;
    }

    return (
        <React.Fragment>
            {ComponentJudgment(store.getState().projectInfo.shortCode)}
        </React.Fragment>
    );
}

export { IOTUpgradeSecond }